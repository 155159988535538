import axios from '@/utils/axios'

// 获取招聘
export function listRecruitByPage(params){
  return axios({
    url:'/api/pc/backstage/recruit/listRecruitByPage',
    method:'get',
    params
  })
}


// 修改招聘
export function updateRecruit(params){
  return axios({
    url:'/api/pc/backstage/recruit/updateRecruit',
    method:'get',
    params
  })
}
