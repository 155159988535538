<template>
  <div class="signupDiaBox">
    <el-dialog :visible.sync="dialogVisible" top="80px" custom-class="noticeDetailsBox" width="80%" :show-close="false"
    :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="signupHeader">
        <div class="signupTitle">选择报考岗位（仅限报考一个岗位）</div>

        <div class="searchBox">
          <ul>
            <li>
              <p>单位：</p>
              <el-select size="small" v-model="unit" filterable placeholder="报考单位">
                <el-option
                  v-for="(item,index) in unitList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <!-- <el-input size="small" v-model="unit" placeholder="请输入内容"></el-input> -->
            </li>
            <li>
              <p>岗位：</p>
              <el-select size="small" v-model="name" filterable placeholder="报考岗位">
                <el-option
                  v-for="(item,index) in positionNameList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <!-- <el-input size="small" v-model="name" placeholder="请输入内容"></el-input> -->
            </li>
            <li>
              <el-button size="small" type="warning" @click="getData">查询</el-button>
            </li>
          </ul>
        </div>

        <div class="tableBox">
          <el-table :data="tableData" :row-style="rowClass" style="width: 100%" max-height="500">
            <el-table-column align="center" label="选择" width="50" fixed="left">
              <template slot-scope="scope">
                <div>
                  <el-radio @change="radioChange" v-model="tableRadio" :label="scope.row.id">{{ }}</el-radio>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="unit" label="单位" width="120"></el-table-column>
            <el-table-column align="center" prop="name" label="招聘岗位" width="120"></el-table-column>
            <el-table-column align="center" prop="describea" label="岗位简介" width="200"></el-table-column>
            <!-- <el-table-column align="center" prop="type" label="电子邮件" width="120"></el-table-column> -->
            <!-- <el-table-column align="center" prop="gwjb" label="岗位级别" width="120"></el-table-column> -->
            <el-table-column align="center" prop="positionNum" label="招聘人数" width="100"></el-table-column>
            <el-table-column align="center" prop="censusRegisterRequire" label="户籍要求" width="120"></el-table-column>
            <el-table-column align="center" prop="educationBackgroundRequire" label="学历要求" width="140"></el-table-column>
            <el-table-column align="center" prop="diplomaRequire" label="学位要求" width="140"></el-table-column>
            <el-table-column align="center" prop="majorRequire" label="专业要求" width="150"></el-table-column>
            <!-- <el-table-column align="center" prop="ageRequire" label="年龄要求" width="100"></el-table-column> -->
            <!-- <el-table-column align="center" prop="politicalLandscapeRequire" label="政治面貌" width="100"></el-table-column> -->
            <el-table-column align="center" prop="otherRequire" label="其他条件" width="140"></el-table-column>
            <el-table-column align="center" prop="needInterview" label="是否面试" width="140">
              <template slot-scope="scope">
                <div>
                  <span>{{scope.row.needInterview==1?'否':'是'}}</span>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column align="center" prop="planScale" label="计划聘用人数与面试人选确定比例" width="110"></el-table-column> -->
            <el-table-column align="center" prop="positionPhone" label="联系电话" width="100"></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="closeBox">
        <el-button style="width:100px" @click="handleClose">关闭</el-button>
        <el-button type="danger" class="closeBtn" @click="startSignupDia">开始报名</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {getUnitAndPositionList } from "@/api/index";
import {listPosition,checkApply} from '@/api/recruitPost/recruitPost'
import {mapMutations} from 'vuex'
export default {
  model:{
    prop:'dialogVisible',
    event:'change'
  },
  props:['dialogVisible','recruitId'],
  data() {
    return {
      unit:'',
      name:'',
      tableData:[],
      tableRadio:'',
      radioObj:null,
      positionNameList: [], //岗位集合
      unitList: [], // 单位
      selectRow:[],
    }
  },
  watch:{
    radioObj(data) {
      this.selectRow = [];
      // if (data.length > 0) {
        // data.forEach((item, index) => {
        //   this.selectRow.push(this.tableData.indexOf(item));
        // });
        this.selectRow.push(this.tableData.indexOf(data));

      // }
    }
  },
  mounted() {
    this.getData()
    this.getUnitAndPositionListFun()
  },
  methods:{
    ...mapMutations(['SET_signup']),
    // 多选高亮选中行
    rowClass({row, rowIndex}){
      if(this.selectRow.includes(rowIndex)){
        return { "background-color": "rgba(185, 221, 249, 0.75)" }
      }
    },
    getUnitAndPositionListFun(){
      let parm = {
        recruitId: this.recruitId,
        ownToken:this.$ownToken,
      }
      getUnitAndPositionList(parm).then(res=>{
        this.positionNameList = res.data.positionNameList
        this.unitList = res.data.unitNameList
      })
    },
    // 获取岗位列表
    getData(){
      let data = {
        recruitId:this.recruitId,
        ownToken:this.$ownToken,
        unit:this.unit,
        name:this.name
      }
      listPosition(data).then(res=>{
        if(res.status == 200){
          this.tableData = res.data;
        }
      })
    },
    // 表格单选
    radioChange(){
      this.radioObj = this.tableData.filter(item=>{
        return item.id==this.tableRadio;
      })[0];
    },
    // 开始报名
    startSignupDia(){
      let data = {
        ownToken:this.$ownToken,
        recruitId:this.recruitId
      }
      checkApply(data).then(res=>{
        if(res.status == 200){
          if(!this.radioObj){
            this.$message.error('您未选择岗位')
            return
          }
          this.radioObj.recruitId = this.recruitId
          this.SET_signup(this.radioObj)
          this.$emit('startSignup')
          // this.$emit('change',false)
        }else{
          this.$message.error(res.message)
        }
      })
    },
    // 关闭弹框
    handleClose(){
      this.$emit('change',false)
    }
  }
}
</script>
<style lang="scss" scoped>
  .signupDiaBox{
    .signupHeader{
      padding: 0 30px;
      .signupTitle{
        font-size: 18px;
        font-weight: 550;
      }

      .searchBox{
        margin-top: 25px;
        ul{
          display: flex;

          li{
            display: flex;
            align-items: center;
            margin-right: 25px;

            p{
              width: 55px;
            }
          }
        }
      }
    }

    .closeBox{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      padding: 10px 70px;
      border-top: 1px solid #DADADA;


      .closeBtn{
        width: 100px;
        height: 40px;
        background: rgb(189, 25, 44);
        border: none;
      }
    }
  }
</style>
