<template>
  <div class="recruitPostBox">
    <div class="recruitHeader">
      <img :src="recruitIcon" alt="">
      <p>招聘事项</p>
    </div>

    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
        <el-table-column align="center" prop="title" label="招聘事项" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="time" label="报名时间" width="280"></el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <div>
              <p class="signUpColor" @click="signUpFun(scope.row)">报名</p>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        @current-change="currentChange"
        layout="prev, pager, next"
        :page-size="10"
        :current-page="page"
        :total="total">
      </el-pagination>
    </div>

    <!-- 岗位 -->
    <signupDia v-if="dialogVisible" :recruitId="recruitId" v-model="dialogVisible" @startSignup="startSignup" />

    <!-- 填写报名信息 -->
    <signupProcess v-if="processVisible" v-model="processVisible" />
  </div>
</template>
<script>
import recruitIcon from '@/assets/recruit-icon.png'
import signupDia from '@/views/recruitPost/signupDia'
import signupProcess from '@/components/signupProcess'
import {listRecruitByPage} from '@/api/recruitmentMatters/recruitmentMatters';
export default {
  components:{
    signupDia,
    signupProcess
  },
  data() {
    return {
      recruitIcon,
      dialogVisible:false,
      processVisible:false,
      total:0,
      page:1,
      tableData:[],
      recruitId:''
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    // 获取列表
    getData(){
      let data = {
        page:this.page,
        limit:10,
        ownToken:this.$ownToken
      };
      listRecruitByPage(data).then(res=>{
        this.tableData = res.data.rows.map(item=>{
          if(item.beginTime&&item.endTime){
            let bIndex = item.beginTime.lastIndexOf(':');
            let bigin = item.beginTime.substring(0,bIndex).replace(/-/g,'.');
            let eIndex = item.endTime.lastIndexOf(':');
            let end = item.endTime.substring(0,eIndex).replace(/-/g,'.');
            item.time = bigin+' ~ '+end
          }
          return item
        });
        this.total = res.data.total;
      })
    },
    // 开始报名
    startSignup(){
      this.processVisible = true;
    },
    // 报名
    signUpFun(row){
      this.recruitId = row.id;
      this.dialogVisible = true;
    },
    // 分页
    currentChange(val){
      this.page = val;
      this.getData()
    },
  }
}
</script>
<style lang="scss" scoped>
  .recruitPostBox{
    .recruitHeader{
      display: flex;
      padding: 25px 20px;
      p{
        margin-left: 10px;
        color: #bf162a;
        font-weight: 600;
      }
    }

    .signUpColor{
      color: rgb(17, 166, 234);
      cursor: pointer;
    }
  }
</style>