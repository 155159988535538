import { render, staticRenderFns } from "./signupProcess.vue?vue&type=template&id=28510d89&scoped=true&"
import script from "./signupProcess.vue?vue&type=script&lang=js&"
export * from "./signupProcess.vue?vue&type=script&lang=js&"
import style0 from "./signupProcess.vue?vue&type=style&index=0&id=28510d89&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28510d89",
  null
  
)

export default component.exports