<template>
  <div>
    <div class="stepsTitleBox">
      <div class="stepsTitle">诚信承诺</div>
    </div>

    <div class="stepsContent">
      <!-- <div class="chengnuo">本人自愿参加2021年上半年北京市文化和旅游局公开考试录用公务员考试，现郑重承诺：</div>
      <p>一、本人保证报名所提供的个人信息真实、准确、完整，不弄虚作假，不伪造、不使用假证明、假证书并完全符合报名要求。如因个人信息错误、缺失及所提供证明材料虚假造成的一切后果，由本人承担。</p>
      <p>二、本人承诺一旦网上缴费确认参考，如因本人未认真阅读《公告》及报考职(岗)位要求和网上报名程序而导致报名失误或资格不符，以及因本人原因不能参考所产生的一切后果由本人承担。</p>
      <p>三、本人承诺遵守公务员录用考试相关规定，诚信参考，如因违反相关规定而产生的一切后果由本人承担。</p> -->
      <div class="textBox">
        本人已仔细阅读《中国文化遗产研究院2023年度应届毕业生招聘公告》和《中国文化遗产研究院公开招聘编制内工作人员公告》，清楚并理解其内容。
      </div>
      <div class="textBox">
        本人承诺：报名所提供的个人信息真实、准确、完整；遵守事业单位公开招聘相关规定，诚信参考。
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
}
</script>
<style lang="scss" scoped>
  .stepsTitleBox{
    display: flex;
    justify-content: center;
    .stepsTitle{
      height: 50px;
      width: calc(157px * 6);
      background: #FFF0F1;
      color: #000;
      text-align: center;
      line-height: 50px;
      font-weight: bold;
    }
  }

  .stepsContent{
    width: calc(157px * 6);
    margin: 30px auto 50px;
    .chengnuo{
      margin-bottom: 15px;
    }
    p{
      margin:  0 0 10px 15px;
      line-height: 22px;
    }

    .textBox{
      text-indent:2em;
    }
  }
</style>
