<template>
  <div class="registrationBox">
    <div>
      <div class="imgBox">
        <img :src="yes" alt="">
      </div>
      <p>您的报名材料已提交成功，资格初审结果请登录系统后在“个人报名记录”中查询详细信息。如有疑问，可在工作时间电话咨询。</p>
    </div>
  </div>
</template>
<script>
import yes from '@/assets/yes.png'
export default {
  components:{
    
  },
  data() {
    return {
      yes
    }
  },
}
</script>
<style lang="scss" scoped>
  .registrationBox{
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(211, 12, 7);
    .imgBox{
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
  }
</style>