import axios from '@/utils/axios'

// 获取岗位
export function listPosition(params){
  return axios({
    url:'/api/pc/backstage/position/listPosition',
    method:'get',
    params
  })
}

// 新增报名人信息
export function insertExaminee(data){
  return axios({
    url:'/api/pc/backstage/examinee/insertExaminee',
    method:'put',
    data
  })
}

// 修改报名人信息
export function updateExaminee(data){
  return axios({
    url:'/api/pc/backstage/examinee/updateExaminee',
    method:'put',
    data
  })
}

// 检查登入者是否允许申报职位
export function checkApply(data){
  return axios({
    url:'/api/pc/backstage/examinee/checkApply',
    method:'put',
    data
  })
}

