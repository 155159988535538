<template>
  <div class="signupProcess">
    <el-dialog :visible.sync="processVisible" top="50px" custom-class="noticeDetailsBox" width="80%" :show-close="false"
    :close-on-click-modal="false" :close-on-press-escape="false">
        <div class="signupTitle">报名流程</div>
        <div class="stepsBox">
          <ul class="stepsUl">
            <li class="active" v-for="(item,index) in stepsList" :key="index">{{item.name}}</li>
          </ul>
        </div>

        <!--诚信承诺  -->
        <faithPromise v-if="stepActive==1" />
        <!-- 填写信息 -->
        <personalInformation :id="id" :positionId="positionId" :type="type" ref="personalInformation" v-if="stepActive==2&&informationFlag" />

        <!-- 报名成功 -->
        <registration v-if="!informationFlag" />

        <div class="closeTitle" v-if="stepActive==2&&informationFlag&&detilsFlag&&preservationFlag">
          保存信息并关闭页面后，可在个人报名记录中继续填报
        </div>
        <div class="closeBox">

          <el-button type="danger" class="closeBtn" v-if="stepActive==2&&informationFlag&&detilsFlag&&preservationFlag" :loading="loading" @click="submitFun(0)">保存信息</el-button>
          <el-button type="danger" class="closeBtn" v-if="stepActive==2&&informationFlag&&detilsFlag" @click="printingFun">报名表打印预览</el-button>
          <el-button type="danger" class="closeBtn" v-if="stepActive==2&&informationFlag&&detilsFlag" :loading="loading" @click="submitFun(1)">确认并提交</el-button>
          <el-button style="width:100px" @click="handleClose">关闭</el-button>
          <el-button type="danger" class="closeBtn" v-if="stepActive==1&&informationFlag&&detilsFlag" @click="agreeFun">同意</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import faithPromise from '@/components/faithPromise'
import personalInformation from '@/components/personalInformation'
import registration from '@/components/registration'
import {insertExaminee,updateExaminee} from '@/api/recruitPost/recruitPost'
import { mapGetters } from "vuex";
export default {
  model:{
    prop:'processVisible',
    event:'change'
  },
  components:{
    faithPromise,
    personalInformation,
    registration
  },
  props:['processVisible','type','id','positionId'],
  data() {
    return {
      input:'',
      stepActive:1,
      stepsList:[
        {
          name:'1.诚信承诺'
        },
        {
          name:'2.填写并保存信息'
        },
        {
          name:'3.报名表打印签字'
        },
        {
          name:'4.上传电子扫描件'
        },
        {
          name:'5.确认并提交材料'
        },
        {
          name:'6.初步审核'
        }
      ],
      informationFlag:true,
      detilsFlag:true,
      preservationFlag:true,
      loading:false,
      preservationId:''
    }
  },
  computed: {
    ...mapGetters(["signupObj"]),
  },
  mounted() {
    /**
     * type为1代表是审核页面
     * type为2代表是详情
     * type为3代表是暂存信息
     * type为4代表是充报
     * type为空代表新增
     */
    if(this.type==3||this.type==2||this.type==4){
      this.stepActive = 2;
    }
    if(this.type == 2){
      this.detilsFlag = false;
    }
    if(this.type == 4){
      this.preservationFlag = false;
    }
  },
  methods:{
    // 打印
    printingFun(){

      if(this.id||this.preservationId){
        this.$refs.personalInformation.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            //个人证件照
            let { personalObj,ruleForm} = this.$refs.personalInformation;
            if(!personalObj.url){
              this.$message.error('请上传个人证件照')
              return
            }

            let data = {
              ...ruleForm,
              photo:personalObj.url,
              submitType:0,
              ownToken:this.$ownToken
            }
            data.id = data.id?data.id:this.preservationId;
            updateExaminee(data).then(res=>{
              if(res.status == 200){
                let id = this.preservationId?this.preservationId:this.id;
                window.open(`${this.$configs}/api/pc/backstage/examinee/printPreview?id=${id}&ownToken=${this.$ownToken}`)
              }
            })
          }else {
            console.log('error');
            this.$message.error('有必填项未填写')
            return false;
          }
        })
      }else{
        this.$message.error('您还没有保存')
      }
    },
    // 暂存
    temporaryFun(){

    },
    // 确认并提交
    submitFun(types){
      let {
        personalObj,//个人证件照
        idCardObjZ,// 身份证扫描照
        idCardObjF,
        householdObjS,// 户口本扫描照
        householdObjG,
        freshStudentsObjY,// 应届生证明件
        freshStudentsObjC,
        freshStudentsObjL,
        freshStudentsObjW,
        returneesObj,// 资格证
        applicationFormObj,// 报名表扫描件
        certificateFile,
        commitmentLetter,
        ruleForm
      } = this.$refs.personalInformation;
      console.log('certificateFile', certificateFile);
      let data = {
        ...ruleForm,
        experience:ruleForm.experience?ruleForm.experience:null,
        activity:ruleForm.activity?ruleForm.activity:null,
        commend:ruleForm.commend?ruleForm.commend:null,
        otherIntroduce:ruleForm.otherIntroduce?ruleForm.otherIntroduce:null,
        photo:personalObj.url,
        frontCard:idCardObjZ.url,
        backCard:idCardObjF.url,
        firstResidence:householdObjS.url,
        ownResidence:householdObjG.url,
        recommend:freshStudentsObjY.url||'',
        reportCard:freshStudentsObjC.url||'',
        educationPhoto:freshStudentsObjL.url,
        diplomaPhoto:freshStudentsObjW.url,
        degreeCertificate:returneesObj.url||'',
        entryForm:applicationFormObj.url,
        submitType:types,
        ownToken:this.$ownToken,
        certificateFile: certificateFile.url|| '',
        commitmentLetter: commitmentLetter.url || '',
      }
      // types 0==保存   1==提交
      if(types==0){
        this.$refs.personalInformation.$refs['ruleForm'].validateField('idCard',idCardError=>{
          if(!idCardError){
            this.loading = true;
            let fn;
            // type 为3暂存 type 为4重报
            if(this.type==3||this.type==4||this.preservationId){
              fn = updateExaminee
              data.id = data.id?data.id:this.preservationId;
            }else{
              fn = insertExaminee
            }
            console.log(data);
            fn(data).then(res=>{
              if(res.status == 200){
                this.$message.success('保存成功');
                if(res.data){
                  this.preservationId = res.data;
                }
                this.$refs.personalInformation.seticCard()
                // type 为3暂存
                if(this.type==3||this.type==4){
                  this.$emit('getData')
                }

              }else{
                this.$message.error(res.message)
              }
              this.loading = false;
            }).catch(error=>{
              this.loading = false;
            })
          }else{
            this.$message.error('有必填项未填写')

            return false;
          }
        })

      }else{
        this.$refs.personalInformation.$refs['ruleForm'].validate((valid) => {
          if (valid) {

            if(!personalObj.url){
              this.$message.error('请上传个人证件照')
              return
            }
            if(!idCardObjZ.url){
              this.$message.error('请上传身份证正面')
              return
            }
            if(!idCardObjF.url){
              this.$message.error('请上传身份证反面')
              return
            }
            if(!householdObjS.url){
              this.$message.error('请上传户口本首页')
              return
            }
            if(!householdObjG.url){
              this.$message.error('请上传户口本本人页')
              return
            }
            if(!freshStudentsObjL.url){
              this.$message.error('请上传学历证')
              return
            }
            if(ruleForm.examineeCategory!=1) {
              if(!freshStudentsObjW.url){
                this.$message.error('请上传学位证')
                return
              }
            }
            if(ruleForm.positionId === 37 && ruleForm.positionId === 38 || ruleForm.positionId === 39){
              if(!freshStudentsObjY.url){
                this.$message.error('请上传就业推荐表')
                return
              }
              if(!freshStudentsObjC.url){
                this.$message.error('请上传成绩单')
                return
              }
            }
            if(this.signupObj.id==4) {
              if(!certificateFile.url){
                this.$message.error('请上传英语资格证')
                return
              }
            }
            // if(ruleForm.examineeCategory != 1) {
            //   if(!returneesObj.url){
            //     this.$message.error('请上传社会保险个人权益')
            //     return
            //   }
            // }
            // else if(ruleForm.examineeCategory==5){
            //   if(!returneesObj.url){
            //     this.$message.error('请上传资格证')
            //     return
            //   }
            // }

            if(!applicationFormObj.url){
              this.$message.error('请上传报名表')
              return
            }
            // let data = {
            //   ...ruleForm,
            //   photo:personalObj.url,
            //   frontCard:idCardObjZ.url,
            //   backCard:idCardObjF.url,
            //   firstResidence:householdObjS.url,
            //   ownResidence:householdObjG.url,
            //   recommend:freshStudentsObjY.url,
            //   reportCard:freshStudentsObjC.url,
            //   educationPhoto:freshStudentsObjL.url,
            //   diplomaPhoto:freshStudentsObjW.url,
            //   degreeCertificate:returneesObj.url,
            //   entryForm:applicationFormObj.url,
            //   submitType:types,
            //   ownToken:this.$ownToken
            // }
            console.log(data);
            let fn;
            // type 为3暂存 type 为4  重报
            if(this.type==3||this.type==4||this.preservationId){
              fn = updateExaminee
              data.id = data.id?data.id:this.preservationId;
            }else{
              fn = insertExaminee
            }
            this.loading = true;
            fn(data).then(res=>{
              if(res.status == 200){
                if(types==1){
                  this.informationFlag = false;
                }
                // type 为3暂存
                if(this.type==3||this.type==4){
                  this.$emit('getData')
                }

              }else{
                this.$message.error(res.message)
              }
              this.loading = false;
            }).catch(error=>{
              this.loading = false;
            })
          } else {
            console.log('error submit!!');
            this.$message.error('有必填项未填写')
            return false;
          }
        });
      }
    },
    // 同意
    agreeFun(){
      this.stepActive++
    },
    // 关闭弹框
    handleClose(){
      this.$emit('change',false)
    }
  }
}
</script>
<style lang="scss" scoped>
  .signupProcess{
    .signupTitle{
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      color: #000;
    }

    .closeTitle{
      // line-height: 30px;
      margin-top: 10px;
      text-align: center;
      color: #BA0F0C;
    }
    .closeBox{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      padding: 10px 70px;
      border-top: 1px solid #DADADA;


      .closeBtn{
        min-width: 100px;
        height: 40px;
        background: rgb(189, 25, 44);
        border: none;
      }
    }
  }
</style>
